<template>
  <div class="activeLy">
    <div class="banner">
      <img src="@/assets/images/parkBanner.png" alt="" />
    </div>
    <div class="breadBlock">
      <Breadcrumb separator=">" class="bread">
        <!-- <BreadcrumbItem
          v-for="item in $route.query.source"
          @click.native="routeTo(item.path)"
          class="textColorO"
        >{{item.name}}</BreadcrumbItem> -->
        <BreadcrumbItem class="textColor">限时活动</BreadcrumbItem>
        <BreadcrumbItem class="textColor">大转盘抽奖</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="sweepActivitiesB">
      <div class="sweepActivitiesBOne">
        <img src="@/assets/images/active/fontDzp.png" alt="" />
      </div>
      <div class="sweepActivitiesBTwo">
        <div class="sweepActivitiesBTwoB">
          <div class="sweepActivitiesBTwoBGz" @click="model.show = true">
            活动规则
          </div>
          <img
            src="@/assets/images/active/zz.png"
            alt=""
            class="sweepActivitiesBTwoBZz"
            @click="beginRotate"
            :style="rotateStyle"
          />
        </div>
      </div>
      <div class="sweepActivitiesBThree">您还有 {{ count }} 次抽奖机会</div>
      <div class="sweepActivitiesBFour">
        <div class="sweepActivitiesBFourO">奖品如下</div>
        <div class="sweepActivitiesBFourT">
          <div>一等奖</div>
          <div v-if="dzpMessage.prizes">
            {{
              dzpMessage.prizes[0] ? dzpMessage.prizes[0].prize_name : "暂无"
            }}
          </div>
        </div>
        <div class="sweepActivitiesBFourT">
          <div>二等奖</div>
          <div v-if="dzpMessage.prizes">
            {{
              dzpMessage.prizes[1] ? dzpMessage.prizes[1].prize_name : "暂无"
            }}
          </div>
        </div>
        <div class="sweepActivitiesBFourT">
          <div>三等奖</div>
          <div v-if="dzpMessage.prizes">
            {{
              dzpMessage.prizes[2] ? dzpMessage.prizes[2].prize_name : "暂无"
            }}
          </div>
        </div>
      </div>
    </div>
    <dzpGz v-if="model.show" :modal="model"></dzpGz>
    <div class="ItemBg" v-if="showJg" @click="hideModal"></div>
    <div class="ItemBgBox" v-if="showJg">
      <img
        class="ItemBgBoxImg"
        src="@/assets/images/active/gb.png"
        alt=""
        @click="hideModal"
      />
      <div class="ItemBgBoxOne" v-if="resData">一等奖</div>
      <div class="ItemBgBoxTwo" v-if="resData">快去我的优惠券查看吧</div>
      <div class="ItemBgBoxThree" v-if="resData"  @click="routeTo('/quan')">立即查看</div>
      <div class="ItemBgBoxOne" v-if="!resData">未中奖</div>
      <div class="ItemBgBoxTwo" v-if="!resData">很遗憾,没有中奖呢</div>
      <div class="ItemBgBoxThree" v-if="!resData" @click="hideModal">再来一次</div>
    </div>
  </div>
</template>

<script>
// import dzpGz from "./model/dzpGz";
import dzpGz from "./model/dzpGz"
import api from "@/util/api";
import { formatDateTimeT } from "@/util";
import instance from "@/util/instance";
export default {
  name: "activeLy",
  data() {
    return {
      dzpId: this.$route.query.dzpId,
      list: {},
      times:0,
      time: "",
      showJg: false,
      model: {
        show: false,
        id: 0,
      },
      isRotating: false, // 防止重复点击旋转
      rotateAngle: 0, // 当前位置
      count: 0,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      dzpMessage: {},
      resData: {},
    };
  },
  mounted() {
    if(sessionStorage.getItem('token')){
      console.log("this.$route.query", this.$route.query);
      this.search();
      this.searchOne();
      this.searchTwo();
    }else{
         return this.$router.push({path:'/login'});
    }
    
  },
  components: { dzpGz },
  computed: {
    rotateStyle() {
      return `transition: transform ${this.times}ms ease;transform-origin:50% 57%;transform: rotate(${this.rotateAngle}deg) `;
    },
  },
  methods: {
    search() {
      instance.activity_dzp(
        {
          dzpId: this.dzpId,
          id: this.userInfo.userInfo.id,
        },
        (res) => {
          this.dzpMessage = res.data.data;
        }
      );
    },
    searchOne() {
      instance.activityDzpUser(
        {
          dzpId: this.dzpId,
          id: this.userInfo.userInfo.id,
        },
        (res) => {
          this.count = res.data.data;
        }
      );
    },
    searchTwo() {
      console.log("this.dzpId,", this.dzpId);
      instance.addViewCount(
        {
          dzpId: this.dzpId,
        },
        (res) => {}
      );
    },
    showModal() {
      this.showJg = true;
    },
    hideModal() {
      this.showJg = false;
    },
    beginRotate() {
      const { isRotating, rotateAngle } = this;
      if (isRotating) return; // 防止重复点击
      this.times=0;
      this.rotateAngle = 0;
      let deg = 0;
      let name;
      let num = Math.random();

      this.isRotating = true;

      instance.activity_dzp_do(
        {
          dzpId: this.dzpId,
          id: this.userInfo.userInfo.id,
        },
        (res) => {
          this.resData = res.data.data;
          if (res.data.code == 200) {
            if (res.data.data == null) {
              if (num <= 0.25) {
                deg = this.rnd(45, 1);
              } else if (num > 0.25 && num <= 0.5) {
                deg = this.rnd(135, 90);
              } else if (num > 0.5 && num <= 0.75) {
                deg = this.rnd(225, 180);
              } else {
                deg = this.rnd(315, 270);
              }
              console.log("num", num);
              name = "未中奖";
            } else if (
              this.dzpMessage.prizes[2] &&
              res.data.data.id == this.dzpMessage.prizes[2].id
            ) {
              deg = this.rnd(360, 315);
              name = "三等奖";
            } else if (
              this.dzpMessage.prizes[0] &&
              res.data.data.id == this.dzpMessage.prizes[0].id
            ) {
              deg = this.rnd(270, 225);
              name = "一等奖";
            } else if (
              this.dzpMessage.prizes[1] &&
              res.data.data.id == this.dzpMessage.prizes[1].id
            ) {
              deg = this.rnd(180, 135);
              name = "二等奖";
            } else if (
              this.dzpMessage.prizes[2] &&
              res.data.data.id == this.dzpMessage.prizes[2].id
            ) {
              deg = this.rnd(90, 45);
              name = "三等奖";
            } else {
              deg = 22.5;
              name = "未中奖";
            }
          } else {
            deg = 22.5;
            name = "未中奖";
          }
          deg = deg + 4320; // 为了视觉好看，这里默认旋转5圈以上
          this.times=5000;
          this.rotateAngle = deg; // 保存此次旋转角度，下次旋转需要用到
          setTimeout(() => {
            this.isRotating = false; // 本次抽奖结束，可以进行下一次
            console.log("抽中了！",name);
            this.showModal();
            this.searchOne();
          }, 5000);
        }
      );
    },
    //随机数
    rnd(max, min) {
      return Math.round(Math.random() * (max - min)) + min;
    },
  },
};
</script>

<style lang="less" scoped>
.activeLy {
  .banner {
    height: 220px;
  }
  .breadBlock {
    line-height: 40px;
    cursor: pointer;

    .bread {
      width: 1120px;
      margin: auto;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      letter-spacing: 1px;
      .textColor {
        font-weight: 400;
        color: #299654;
      }
    }
    /deep/.ivu-breadcrumb-item-separator {
      color: #929292;
    }
  }
  .main {
    width: 1120px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 24px;
    img {
      width: 1072px;
      height: 431px;
      border-radius: 4px;
    }
    .content {
      margin-top: 22px;
      overflow: hidden;

      .desc {
        display: flex;
        margin-bottom: 40px;
      }
      .item {
        float: left;
        width: 525px;
        display: flex;
        margin-bottom: 16px;
        overflow: hidden;
        margin-right: 20px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      .label {
        width: 78px;
        height: 24px;
        font-size: 18px;
        font-weight: 500;
        color: #4d4d4d;
        line-height: 24px;
        letter-spacing: 1px;
        margin-right: 16px;
        font-weight: 600;
      }

      p {
        flex: 1;
        font-size: 18px;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 27px;
        letter-spacing: 1px;
        text-align: justify;
      }
    }
  }
  .sweepActivitiesBOne {
    padding-top: 32px;
  }
  .sweepActivitiesBOne > img {
    width: 581px;
    height: 117px;
    margin: auto;
  }
  .sweepActivitiesB {
    width: 100%;
    margin: auto;
    height: 1091px;
    background-image: url(../../../assets/images/active/beijing.png);
    background-repeat: no-repeat;
  }
  .sweepActivitiesBTwo {
    margin-top: 28px;
  }
  .sweepActivitiesBTwoB {
    width: 660px;
    height: 660px;
    margin: auto;
    position: relative;
    background-image: url(../../../assets/images/active/dzp.png);
    background-repeat: no-repeat;
  }
  .sweepActivitiesBTwoBGz {
    width: 68px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 11px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
    cursor: pointer;
  }
  .sweepActivitiesBTwoBZz {
    width: 152px;
    height: 176px;
    position: absolute;
    top: 228px;
    left: 256px;
    cursor: pointer;
  }
  .sweepActivitiesBThree {
    margin-top: 24px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    text-align: center;
  }
  .sweepActivitiesBFour {
    margin: 20px auto 0;
    width: 654px;
    height: 156px;
    background: #ffd2d0;
    border-radius: 10px;
    padding: 16px;
  }
  .sweepActivitiesBFourO {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #a84d3d;
    line-height: 22px;
    text-align: center;
  }
  .sweepActivitiesBFourT {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a84d3d;
    line-height: 22px;
    margin-top: 12px;
  }
  .sweepActivitiesBFourT > div:nth-child(1) {
    padding-left: 20px;
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 6px;
      left: 0;
      width: 10px;
      height: 10px;
      background: #ffffff;
      border-radius: 5px;
    }
  }
  .sweepActivitiesBFourT > div:nth-child(2) {
    padding-right: 20px;
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 6px;
      right: 0;
      width: 10px;
      height: 10px;
      background: #ffffff;
      border-radius: 5px;
    }
  }
  .ItemBg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(55, 55, 55, 0.6);
    z-index: 1000;
  }
  .ItemBgBox {
    width: 360px;
    height: 445px;
    background-image: url(../../../assets/images/active/zhongjiangjieguo.png);
    background-repeat: no-repeat;
    position: fixed;
    top: 35%;
    left: 40%;
    z-index: 1001;
  }
  .ItemBgBoxImg {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .ItemBgBoxOne {
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 33px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 230px;
  }
  .ItemBgBoxTwo {
    margin-top: 12px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    text-align: center;
    opacity: 0.6;
  }
  .ItemBgBoxThree {
    width: 120px;
    height: 43px;
    line-height: 43px;
    background: #ffffff;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff512e;
    margin: 56px auto 0;
    cursor: pointer;
  }
}
</style>