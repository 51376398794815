<template>
  <div>
    <Modal
      v-model="modal.show"
      title="活动规则"
      ok-text="提交"
      ref="modal"
      width="30"
      height="400"
      :mask-closable="false"
    >
      <div class="fontDiv mt">一、活动时间：2021.01.04-2021.01.15</div>
      <div class="fontDiv mt">二、活动简介</div>
      <div class="fontDiv">活动期间，每个用户有1次参与大转盘抽奖机会，分享后能增加1次</div>
      <div class="fontDiv mt">三、参与方式</div>
      <div class="fontDiv">点击活动页面“抽奖”即可参与</div>
      <div class="fontDiv mt">四、发放规则</div>
      <div class="fontDiv">1、活动实物奖品将在活动结束后14个工作日内完成发放，中奖实物奖品的用户务务必在2021年1月17号前完善<span class="spanView">（点开抽奖页面-我的奖品-收件信息-编辑）</span>，逾期视为放弃奖品</div>
      <div class="fontDiv">2、活动非实物奖品，请点击中奖页面“立即查收”按钮，添加“长投辅导员”官方微信号，输入关键词“优惠购”领取</div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  name: "dzpGz",
  data() {
    return {
     
    };
  },
  props: {
    modal: {
      default: () => {
        return {
          show: false,
        };
      },
    },
  },
  mounted() {
    
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
.ivu-form-item {
  width: 450px;
  margin: 0 auto 24px;
}
.fontDiv{
  font-size:14px;
  color:rgba(58, 52, 78, 1);
}
.mt{
  margin-top:20px;
}
.spanView{
  font-size:14px;
  color:rgba(255, 0, 0, 1);
}
</style>